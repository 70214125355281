import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    filter: 'sepia(100%)',
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    textAlign: 'center',
  },
  dialogContent: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  onpolar: {
    marginTop: theme.spacing(3),
  },
  textContent: {
    paddingBottom: theme.spacing(4),
  },
}));

const Contact = () => (
  <Typography variant="subtitle1" component="p">
    <Link color="inherit" href="https://goo.gl/maps/WgwpfdE4i4UAF8rn9" rel="noopener noreferrer" target="_blank">
      Punane 16 - 311<br />
      13619 Tallinn, Estonia
    </Link>
    <br />
    <Link color="inherit" href="tel:+3726659861">
      +372 6659861
    </Link>
    <br />
    <a href="mailto:info@navicteam.ee">info@navicteam.ee</a>
  </Typography>
);

const App = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CssBaseline />

      <AppBar position="relative">
        <Toolbar>
          <DirectionsBoatIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
            Navicteam
          </Typography>
        </Toolbar>
      </AppBar>

      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Crewing Agency
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              NavicTeam your way to success!
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button size="large" variant="contained" color="primary" onClick={handleClickOpen}>
                    Contact us
                  </Button>
                  <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                    <DialogTitle id="simple-dialog-title">Navicteam OÜ</DialogTitle>
                    <div className={classes.dialogContent}>
                      <Contact />
                    </div>
                  </Dialog>
                </Grid>
                <Grid item>
                  <Button size="large" variant="outlined" color="primary" href="/navicteam_application_form.doc">
                    Application form
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/haZNHEV2WXQ/600x600"
                    title="Mission"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Our Mission
                    </Typography>
                    <Typography>
                      Assist responsible and demanding customers achieve their business objectives through our
                      professionalism, dedication, enthusiasm and responsiveness.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/kSLNVacFehs/600x600"
                    title="Vision"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Our Vision
                    </Typography>
                    <Typography>
                      To be the leader in quality of crew management using "9 steps selection and recruiting process".
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image="https://source.unsplash.com/HehZymt2IAg/600x600"
                  title="Goals"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Our Goals
                  </Typography>
                  <Typography>
                    Our first priority has been and is the safe operation of the vessels we manage, with no injuries,
                    loss of life and damage to property or the environment.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <div className={classes.textContent}>
          <Container maxWidth="md">
            <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
              Crewing Agency in Tallinn, Estonia
            </Typography>
            <Typography color="textSecondary" paragraph>
              Navicteam OÜ is a Crewing Agency based in Tallinn, Estonia that can supply seafarers from Eastern Europe, CIS
              countries and Ukraine with experience on all types of vessels including Offshore and Passenger fleet.
            </Typography>
            <Typography color="textSecondary" paragraph>
              By way of background the company was founded in 2015, on a basis of former Schulte Group Recruiting
              and Shipping Office in Tallinn and is fully licensed by Estonian Authorities for recruiting activities.
            </Typography>
            <Typography color="textSecondary" paragraph>
              The company is managed by experienced personnel that have an in-depth understanding of your business requirements.
            </Typography>
            <Typography color="textSecondary" paragraph>
              All sea staff recruited by our company hold valid certificates, licenses, endorsements and other necessary documents required by STCW78/95-2010. All seamen speak proficient English and have an experience of working with mixed nationality crews.
            </Typography>
            <Typography color="textSecondary" paragraph>
              As a Crewing Agency we are able to perform all customary management functions related to crewing. We will also ensure that the vessel is manned by qualified and adequately trained Officers, Ratings and passenger vessels hotel personnel as well.
            </Typography>
          </Container>
        </div>
      </main>

      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Navicteam OÜ
        </Typography>
        <Contact />
        <Typography variant="body3" color="textSecondary" component="p" className={classes.onpolar}>
          <Link color="inherit" href="https://www.onpolar.com" rel="noopener noreferrer" target="_blank">Website by Onpolar</Link>
        </Typography>
      </footer>
    </>
  );
}

export default App;
